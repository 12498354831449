import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme, Skeleton, Box, BoxProps } from '@mui/material';


type VideoProps = {
  videoProps: React.VideoHTMLAttributes<HTMLVideoElement>
};

export const Video = ({ videoProps: { src, ...videoProps } }: VideoProps) => {

  const videoRef = React.useRef<HTMLVideoElement>(null);

  const [hasLoaded, setHasLoaded] = useState(false);

  const onLoad = () => {
    setHasLoaded(true);
  }

  const theme = useTheme();
	const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const onMouseEnter = () => {
    if (videoRef.current && isMdUp) {
      videoRef.current.playbackRate = 5;
      videoRef.current.play();
    }
  }

  const onMouseLeave = () => {
    if (videoRef.current && isMdUp) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {/*!hasLoaded &&
        <Skeleton
          height="100%"
          variant="rounded"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
      */}

      <video
        ref={videoRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onLoad={onLoad}
        playsInline
        muted
        autoPlay
        {...videoProps}
      >
        <source src={src!.replace(".mp4", ".webm")} type="video/webm" />
        <source src={src!.replace(".webm", ".mp4")} type="video/mp4" />
      </video>
    </Box>
  );

}
