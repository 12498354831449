import React from "react";
import { IntlProvider } from "react-intl";

export enum Locale {
  EN = "en-US",
  HE = "he",
};

export const defaultLocale = Locale.EN;


export const LocaleProvider: React.FC = ({ children }) => {

  return (
    <IntlProvider 
      messages={{}} 
      locale={defaultLocale}
      defaultLocale={defaultLocale}
    >
      {children}
    </IntlProvider>
  );

}